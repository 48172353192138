import useModalProps from "../hooks/useModalProps";
import ScrollContainer from "../components/ScrollContainer";

const FormInput =  React.lazy(() => import( /* webpackChunkName: "FormInput" */ '../components/FormInput'));

function FlagSelectModal ( props )
{
    const { onSelect } = useModalProps ( 'reactFlagSelectModal', props, { onSelect: undefined } );
    const [ phoneSearch, setPhoneSearch ] = React.useState ( '' );

    const countriesFiltered = React.useMemo ( () =>
    {
        return window.countryMasks.filter ( country =>
            country.name.toLowerCase ().includes ( phoneSearch.toLowerCase ().trim () )
            || country.code.toLowerCase ().includes ( phoneSearch.toLowerCase ().trim () )
            || country.phone.toLowerCase ().includes ( phoneSearch.toLowerCase ().trim () ) );
    }, [ phoneSearch ] );

    const selectCountry = country =>
    {
        $.fancybox.close ();
        setPhoneSearch ( '' );
        onSelect && onSelect ( country );
    };

    return <div>
        <div className="reg-modal__title">
            { tMsg ( 'Код страны' ) }
        </div>
        <div className="phone-code__form">
            <div></div>
            <FormInput
                className={ 'reg-input' }
                label={ tMsg ( 'Поиск по коду страны' ) }
                value={ phoneSearch }
                onChange={ setPhoneSearch }
            />
            <ScrollContainer className="country-list__wrapp">
                <ul className="country-list">
                    { countriesFiltered.map ( ( country, index ) => <li
                        key={ `countryMask_${ index }` }
                        onClick={ () => selectCountry ( country ) }
                    >
                        <img src={ `${ themeDir }/images/flags/${ country.code.toLowerCase () }.svg` }/>
                        <span>{ country.name } { country.phone }</span>
                    </li> ) }
                </ul>
            </ScrollContainer>
        </div>
    </div>;
}

const domContainer = document.querySelector ( '#reactFlagSelectModal' );
const root = ReactDOM.createRoot ( domContainer );
root.render ( React.createElement ( FlagSelectModal ) );

const modalPropsReducer = ( state, action ) =>
{
    switch ( action.type )
    {
        case 'setProps':
            return action.props;
        default:
            return state;
    }
};
const useModalProps = ( modalId, componentProps, initial ) =>
{
    const [ props, dispatch ] = React.useReducer ( modalPropsReducer, initial, () => ( { ...componentProps } ) );

    React.useEffect ( () =>
    {
        const registerProps = function ( e, instance )
        {
            if ( instance.current.src === '#' + modalId )
            {
                dispatch ( { type: 'setProps', props: { ...initial, ...( instance.current.props || {} ) } } );
            }
        };

        const unregisterProps = function ( e, instance )
        {
            if ( instance.current.src === '#' + modalId )
            {
                dispatch ( { type: 'setProps', props: initial } );
            }
        };

        $ ( document ).on ( 'afterShow.fb', registerProps );
        $ ( document ).on ( 'afterClose.fb', unregisterProps );

        return () =>
        {
            $ ( document ).off ( 'afterShow.fb', registerProps );
            $ ( document ).off ( 'afterClose.fb', unregisterProps );
        };
    }, [ modalId, componentProps, initial ] );

    return props;
};

export default useModalProps;
export default function ScrollContainer ( { className, children } )
{
    const [ node, setNode ] = React.useState ();

    const handleRef = React.useCallback ( ( node ) =>
    {
        setNode ( node );
    } );

    React.useEffect ( () =>
    {
        if ( node )
        {
            new SimpleBar ( node );
        }
    }, [ node ] );

    return <div ref={ handleRef } className={ className }>
        { children }
    </div>;
}